Ember.TEMPLATES["javascripts/discourse-survey/connectors/topic-footer-main-buttons-before-create/send-survey"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"topic\",\"custom_fields\",\"survey_sent\"]]],null,{\"statements\":[[1,[28,\"d-button\",null,[[\"icon\",\"label\",\"title\",\"disabled\"],[\"check\",\"survey.has_sent.title\",\"survey.has_sent.help\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,[28,\"d-button\",null,[[\"id\",\"icon\",\"action\",\"label\",\"title\"],[\"survey_button\",\"paper-plane\",\"sendSurvey\",\"survey.not_sent.title\",\"survey.not_sent.help\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse-survey/connectors/topic-footer-main-buttons-before-create/send-survey"}});
define("discourse/plugins/survey/discourse-survey/connectors/topic-footer-main-buttons-before-create/send-survey", ["exports", "discourse/lib/show-modal", "discourse/lib/ajax"], function (_exports, _showModal, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(args, component) {
      var needsButton = component.currentUser && component.currentUser.get('staff');
      return needsButton && !component.get('site.mobileView');
    },
    actions: {
      sendSurvey: function sendSurvey() {
        var container = Discourse.__container__;
        var model = {
          topic_id: this.topic.id
        };
        var controller = container.lookup('controller:survey');
        controller.set('model', model);
        controller.send('sendSurvey');
      }
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/survey/discourse-survey/controllers/survey", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { default as computed } from 'ember-addons/ember-computed-decorators';
  var _default = Ember.Controller.extend({
    actions: {
      sendSurvey: function sendSurvey() {
        var path = '/survey/send_survey';
        return (0, _ajax.ajax)(path, {
          type: 'POST',
          data: {
            topic_id: this.get('model.topic_id')
          }
        }).then(function () {
          window.location.reload(true);
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  });

  _exports.default = _default;
});

